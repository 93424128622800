import React, { useMemo } from "react"
import { Link } from "gatsby"

import { useWindowSize } from "../../hooks"
import { BiRightArrowAlt } from "react-icons/bi"
import "./styles.css"

export type PlansData = {
  title: string
  description: string
  isMostPopular?: boolean
  features: Array<string>
  className?: string
  classNameMobile?: string
  baseColor: string
  baseAuxColor: string
}

export type PlanFeatures = {
  title: string
  items: Array<string>
}

interface PricingDataGridProps {
  features: Array<PlanFeatures>
  plans: Array<PlansData>
}

const PricingDataGrid: React.FC<PricingDataGridProps> = ({
  features,
  plans,
}) => {
  const { width } = useWindowSize()

  // It makes data connection between features and plans easier
  // Used by the Web version of the component only.
  const featurePlanPair = useMemo(
    () =>
      features.map(feature => ({
        ...feature,
        items: feature.items.map(featureItem => {
          const featurePerPlan = new Array(plans.length)
            .fill(0)
            .map((_, i) => plans[i].features.includes(featureItem))

          return {
            label: featureItem,
            featurePerPlan,
          }
        }),
      })),
    [features, plans]
  )

  const plansWithClassifiedFeatures = useMemo(
    () =>
      plans.map(plan => ({
        ...plan,
        features: features
          .map(feature => ({
            title: feature.title,
            items: feature.items.filter(featureItem =>
              plan.features.includes(featureItem)
            ),
          }))
          .filter(feature => feature.items.length > 0),
      })),
    [features, plans]
  )

  return (
    <div className="app-pricing-data-grid-wrapper">
      {width && width <= 480 ? (
        <div className="plans-features-mobile">
          {plansWithClassifiedFeatures.map(planWithClassifiedFeatures => (
            <div
              className="plan-wrapper"
              style={{
                backgroundColor: planWithClassifiedFeatures.baseAuxColor,
              }}
            >
              <div
                className={[
                  "plan-header",
                  planWithClassifiedFeatures.classNameMobile,
                ]
                  .filter(Boolean)
                  .join(" ")}
                style={{
                  backgroundColor: planWithClassifiedFeatures.baseColor,
                }}
              >
                {/* {planWithClassifiedFeatures.isMostPopular && ( */}
                <span
                  style={{
                    display: planWithClassifiedFeatures.isMostPopular
                      ? "block"
                      : "none",
                  }}
                >
                  MOST POPULAR
                </span>

                <h2>{planWithClassifiedFeatures.title}</h2>
                <div style={{ display: "flex", flexDirection: "row", gap: 12 }}>
                  <Link
                    to={
                      planWithClassifiedFeatures.title === "Enterprise"
                        ? "/get-a-demo"
                        : "/try-for-free"
                    }
                  >
                    {planWithClassifiedFeatures.title === "Enterprise"
                      ? "CONTACT US"
                      : "TRY NOW"}
                  </Link>
                  <Link className="schedule-call-button" to="/get-a-demo">
                    Schedule Call
                  </Link>
                </div>
                <p
                  style={{
                    fontSize: 12,
                    marginTop: 12,
                    color: "white",
                    visibility:
                      planWithClassifiedFeatures.title === "Enterprise" ||
                        planWithClassifiedFeatures.title === "Free"
                        ? "hidden"
                        : "visible",
                  }}
                >
                  Contact us for trial options
                </p>
              </div>

              <div className="plan-features">
                {planWithClassifiedFeatures.features.map(feature => (
                  <div>
                    <h3>{feature.title}</h3>

                    {feature.items.map(featureItem => (
                      <span>
                        <img
                          src="/img/featured/v-check.svg"
                          alt="v check icon"
                        />

                        <p>{featureItem}</p>
                      </span>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <div className="plans-features">
            <table>
              <colgroup>
                <col className="empty-column" />

                {plans.map(({ baseAuxColor }) => (
                  <col
                    className="plan-column"
                    style={{ backgroundColor: baseAuxColor }}
                  />
                ))}
              </colgroup>

              <thead>
                <tr>
                  <th></th>

                  {plans.map(plan => (
                    <th>
                      <div className="plan-data-wrapper">
                        <div
                          className={["plan-data-info", plan.className]
                            .filter(Boolean)
                            .join(" ")}
                          style={{ backgroundColor: plan.baseColor }}
                        >
                          {plan.isMostPopular && <span>MOST POPULAR</span>}

                          <h2>{plan.title}</h2>

                          <Link
                            to={
                              plan.title === "Enterprise"
                                ? "/get-a-demo"
                                : "/try-for-free"
                            }
                          >
                            {plan.title === "Enterprise"
                              ? "CONTACT US"
                              : "TRY NOW"}
                          </Link>
                          <Link to="/get-a-demo" className="plan-schedule">
                            Schedule a call
                            <BiRightArrowAlt size={24} />
                          </Link>
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {featurePlanPair.map(({ items, title }) => (
                  <>
                    <tr>
                      <td className="data-feature-title">{title}</td>

                      {new Array(plans.length).fill(false).map(() => (
                        <td style={{ height: "100%" }}></td>
                      ))}
                    </tr>

                    {items.map((item, itemIndex) => (
                      <tr
                        className={
                          items.length - 1 === itemIndex
                            ? "data-feature-last"
                            : ""
                        }
                      >
                        <td
                          className="data-feature-item-title"
                          style={{ height: "100%" }}
                        >
                          {item.label}
                        </td>

                        {item.featurePerPlan.map(included => (
                          <td
                            className="data-feature-item"
                            style={{ height: "100%" }}
                          >
                            {included ? (
                              <img
                                src="/img/featured/v-check.svg"
                                alt="v check icon"
                              />
                            ) : (
                              ""
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}

                    <tr>
                      <td>
                        <hr style={{ borderTop: "1px solid #82828251" }} />
                      </td>

                      {plans.map(({ baseColor }) => (
                        <td>
                          <hr
                            style={{
                              width: "80%",
                              borderTop: `1px solid ${baseColor}51`,
                            }}
                          />
                        </td>
                      ))}
                    </tr>
                  </>
                ))}
              </tbody>

              <tfoot>
                <tr>
                  <td></td>

                  {plans.map(plan => (
                    <td className="table-foot-item">
                      <div
                        style={{
                          backgroundColor: plan.baseAuxColor,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: plan.baseAuxColor,
                          }}
                        >
                          <Link
                            to={
                              plan.title === "Enterprise"
                                ? "/get-a-demo"
                                : "/try-for-free"
                            }
                          >
                            {plan.title === "Enterprise"
                              ? "CONTACT US"
                              : "TRY NOW"}
                          </Link>
                        </div>
                        <p
                          style={{
                            marginLeft: 20,
                            fontSize: 12,
                            marginTop: 12,
                            visibility:
                              plan.title === "Enterprise" ||
                                plan.title === "Free"
                                ? "hidden"
                                : "visible",
                          }}
                        >
                          Contact us for trial options
                        </p>
                      </div>
                    </td>
                  ))}
                </tr>
              </tfoot>
            </table>
          </div>
        </>
      )}
    </div>
  )
}

export { PricingDataGrid }
