import React, { useMemo } from "react"
import { Link } from "gatsby"

import { Layout } from "../../components/Layout"

import {
  PricingDataGrid,
  PlanFeatures,
  PlansData,
} from "../../components/PricingDataGrid"

import "./styles.css"
import { Helmet } from "react-helmet"

const ALL_FEATURES = {

  // IrisGPT Chatbot
  IRISGPT_CHATBOT: "IrisGPT Chatbot with GenAI Answers",
  AGENT_HANDOFF: "Seamless Agent Handoff",
  CHATBOT_WORKFLOWS: "Chatbot Workflows and Decision Trees",

  // AI Powered Issue Discovery and Triaging
  EARLY_WARNING_ENGINE: "Early Warning Engine",
  SMART_TAGGING: "Automated Tagging",
  AUTOMATED_SUPPORT_TRIAGING: "Automated Support Triaging",
  AGENT_ASSIST: "AI Answers for Agent Assist",
  CASE_SUMMARIZATION: "Real-time Ticket Summary",
  AUTOMATED_ROUTING: "Intelligent Routing with Decision Trees",

  // Analytics
  SENTIMENT_ANALYSIS: "Sentiment and Customer Health Analysis",
  REVENUE_IMPACT_ANALYSIS: "Revenue Impact Analysis",
  PRODUCT_QUALITY_MONITORING: "Product Quality Monitoring",
  SERVICE_AVAILABILITY_ANALYSIS: "Service Availability Analysis",

  // Data and Workflows
  CRM_CONNECTORS: "CRM Connectors",
  DEVOPS_AND_ENGINEERING_CONNECTORS: "DevOps and Engineering Connectors",
  CSM_CONNECTORS: "CSM Connectors",
  PRODUCT_MANAGEMENT_CONNECTORS: "Product Management Connectors",
  DEVOPS_AND_ENGINEERING_ALERTS: "DevOps and Engineering Alerts",
  SUPPORT_ALERTS: "Support Alerts",
  RCA_NOTIFICATIONS: "RCA Notifications",

  // Service & Support
  CUSTOM_DEPLOYMENT: "Custom Deployment",
  TRAINING: "Training",
  EMAIL_SUPPORT: "Email Support",
  PHONE_SUPPORT: "Phone Support",
  QUARTERLY_HEALTH_CHECK: "Quarterly Health Check",
}

export default function Pricing() {
  const features = useMemo<Array<PlanFeatures>>(
    () => [
      {
        title: "IRISGPT CHATBOT",
        items: [
          ALL_FEATURES.IRISGPT_CHATBOT,
          ALL_FEATURES.AGENT_HANDOFF,
          ALL_FEATURES.CHATBOT_WORKFLOWS,
        ],
      },{
        title: "AI POWERED TRIAGING AND AGENT ASSIST",
        items: [
          ALL_FEATURES.EARLY_WARNING_ENGINE,
          ALL_FEATURES.SMART_TAGGING,
          ALL_FEATURES.AUTOMATED_SUPPORT_TRIAGING,
          ALL_FEATURES.AGENT_ASSIST,
          ALL_FEATURES.CASE_SUMMARIZATION,
          ALL_FEATURES.AUTOMATED_ROUTING,
        ],
      },
      {
        title: "PREDICTIVE ANALYTICS",
        items: [
          ALL_FEATURES.SENTIMENT_ANALYSIS,
          ALL_FEATURES.REVENUE_IMPACT_ANALYSIS,
          ALL_FEATURES.PRODUCT_QUALITY_MONITORING,
          ALL_FEATURES.SERVICE_AVAILABILITY_ANALYSIS,
        ],
      },
      {
        title: "DATA AND WORKFLOWS",
        items: [
          ALL_FEATURES.CRM_CONNECTORS,
          ALL_FEATURES.DEVOPS_AND_ENGINEERING_CONNECTORS,
          ALL_FEATURES.CSM_CONNECTORS,
          ALL_FEATURES.PRODUCT_MANAGEMENT_CONNECTORS,
          ALL_FEATURES.DEVOPS_AND_ENGINEERING_ALERTS,
          ALL_FEATURES.SUPPORT_ALERTS,
          ALL_FEATURES.RCA_NOTIFICATIONS,
        ],
      },
      {
        title: "SERVICE & SUPPORT",
        items: [
          ALL_FEATURES.CUSTOM_DEPLOYMENT,
          ALL_FEATURES.TRAINING,
          ALL_FEATURES.EMAIL_SUPPORT,
          ALL_FEATURES.PHONE_SUPPORT,
          ALL_FEATURES.QUARTERLY_HEALTH_CHECK,
        ],
      },
    ],
    []
  )

  const plans = useMemo<Array<PlansData>>(
    () => [
      {
        title: "Free",
        description: "Best for Startups",
        baseColor: "#EF5DA8",
        baseAuxColor: "rgba(239, 93, 168, 0.04)",
        className: "plan-basic-style",
        classNameMobile: "plan-basic-style-mobile",
        features: [
          ALL_FEATURES.IRISGPT_CHATBOT,
          ALL_FEATURES.CRM_CONNECTORS,
          ALL_FEATURES.EMAIL_SUPPORT,
          ALL_FEATURES.SMART_TAGGING,
        ],
      },
      {
        title: "Standard",
        description: "Best for Teams/SMEs",
        baseColor: "#5D5FEF",
        baseAuxColor: "rgba(93, 95, 239, 0.04)",
        className: "plan-advanced-style",
        classNameMobile: "plan-advanced-style-mobile",
        isMostPopular: true,
        features: [
          ALL_FEATURES.IRISGPT_CHATBOT,
          ALL_FEATURES.AGENT_HANDOFF,
          ALL_FEATURES.CHATBOT_WORKFLOWS,
          ALL_FEATURES.CRM_CONNECTORS,
          ALL_FEATURES.DEVOPS_AND_ENGINEERING_CONNECTORS,
          ALL_FEATURES.DEVOPS_AND_ENGINEERING_ALERTS,
          ALL_FEATURES.SUPPORT_ALERTS,
          ALL_FEATURES.EARLY_WARNING_ENGINE,
          ALL_FEATURES.SMART_TAGGING,
          ALL_FEATURES.AUTOMATED_ROUTING,
          ALL_FEATURES.AUTOMATED_SUPPORT_TRIAGING,
          ALL_FEATURES.AGENT_ASSIST,
          ALL_FEATURES.CASE_SUMMARIZATION,
          ALL_FEATURES.SENTIMENT_ANALYSIS,
          ALL_FEATURES.REVENUE_IMPACT_ANALYSIS,
          ALL_FEATURES.PRODUCT_QUALITY_MONITORING,
          ALL_FEATURES.EMAIL_SUPPORT,
          ALL_FEATURES.PHONE_SUPPORT,
        ],
      },
      {
        title: "Enterprise",
        description: "Best for large organizations",
        baseColor: "#6FCF97",
        baseAuxColor: "rgba(111, 207, 151, 0.04)",
        className: "plan-enterprise-style",
        classNameMobile: "plan-enterprise-style-mobile",
        features: [
          ALL_FEATURES.IRISGPT_CHATBOT,
          ALL_FEATURES.AGENT_HANDOFF,
          ALL_FEATURES.CHATBOT_WORKFLOWS,
          ALL_FEATURES.CRM_CONNECTORS,
          ALL_FEATURES.DEVOPS_AND_ENGINEERING_CONNECTORS,
          ALL_FEATURES.CSM_CONNECTORS,
          ALL_FEATURES.PRODUCT_MANAGEMENT_CONNECTORS,
          ALL_FEATURES.DEVOPS_AND_ENGINEERING_ALERTS,
          ALL_FEATURES.SUPPORT_ALERTS,
          ALL_FEATURES.RCA_NOTIFICATIONS,
          ALL_FEATURES.EARLY_WARNING_ENGINE,
          ALL_FEATURES.SMART_TAGGING,
          ALL_FEATURES.AUTOMATED_ROUTING,
          ALL_FEATURES.AUTOMATED_SUPPORT_TRIAGING,
          ALL_FEATURES.AGENT_ASSIST,
          ALL_FEATURES.CASE_SUMMARIZATION,
          ALL_FEATURES.SENTIMENT_ANALYSIS,
          ALL_FEATURES.REVENUE_IMPACT_ANALYSIS,
          ALL_FEATURES.PRODUCT_QUALITY_MONITORING,
          ALL_FEATURES.SERVICE_AVAILABILITY_ANALYSIS,
          ALL_FEATURES.CUSTOM_DEPLOYMENT,
          ALL_FEATURES.TRAINING,
          ALL_FEATURES.EMAIL_SUPPORT,
          ALL_FEATURES.PHONE_SUPPORT,
          ALL_FEATURES.QUARTERLY_HEALTH_CHECK,
        ],
      },
    ],
    []
  )

  return (
    <Layout
      keywords="pricing, proactive customer support, AI in customer support"
      title="Check Out The Pricing Of Our World Class Customer Support Platform"
      description="IrisAgent delivers world class customer support services and you can try it for free today without a credit card. Take a look at the various  pricing tiers we offer."
    >
      <Helmet>
        <link rel="canonical" href="https://irisagent.com/pricing/" />
      </Helmet>
      <section className="app-pricing-page-section">
        <div className="header">
          <h1>Deliver world class support</h1>

          <h3>Get started with the power of Generative AI for free</h3>
        </div>

        <div className="content">
          <PricingDataGrid features={features} plans={plans} />
        </div>
      </section>
    </Layout>
  )
}
